<template>
	<svg
		width="100"
		height="100"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#gh436h)">
			<path
				d="m50.424.136 17.35 17.356-33.472 33.473-26.91-26.89A49.628 49.628 0 0 0 .132 50c0 1.553.076 3.091.214 4.61l11.977 10.076 4.543-2.788 71.454-43.812A49.763 49.763 0 0 0 50.423.136Z"
				fill="#3057AC"
				stroke="#000"
				stroke-miterlimit="10"
			/>
			<path
				d="m43.907 92.377-5.989-14.26L75.084 62.5l9.749 23.193a49.864 49.864 0 0 0 3.486-67.607L16.864 61.899l27.043 30.478Z"
				fill="#E0AD26"
				stroke="#000"
				stroke-miterlimit="10"
			/>
			<path
				d="m16.864 61.898-4.542 2.788L.345 54.61A49.874 49.874 0 0 0 47.02 99.778l-3.112-7.4-27.042-30.48Z"
				fill="#457730"
				stroke="#000"
				stroke-miterlimit="10"
			/>
			<path
				d="m37.918 78.117 5.994 14.26 6.642 7.484A49.686 49.686 0 0 0 84.838 85.68L75.084 62.5 37.918 78.117Z"
				fill="#C53A27"
				stroke="#000"
				stroke-miterlimit="10"
			/>
			<path
				d="M67.775 17.492 50.424.136 50 .131A49.838 49.838 0 0 0 7.393 24.074l26.9 26.899 33.482-33.48Z"
				fill="#F5F5F5"
				stroke="#000"
				stroke-miterlimit="10"
			/>
			<path
				d="M47.019 99.778c.986.057 1.98.091 2.981.091h.55l-6.643-7.484 3.112 7.393Z"
				fill="#000"
				stroke="#000"
				stroke-miterlimit="10"
			/>
			<path
				d="M48.998 45.664c1.504-1.07 3.599-1.71 5.981-1.71 3.74 0 6.744 1.538 8.002 3.773h4.613C66.58 41.79 61.478 37.53 54.98 37.53c-3.661 0-6.888 1.36-9.184 3.611a12.115 12.115 0 0 0-3.431 6.574 13.317 13.317 0 0 0-.199 2.283 12.464 12.464 0 0 0 1.951 6.8c2.23 3.465 6.182 5.687 10.87 5.687 1.78.01 3.545-.33 5.195-.996a12.064 12.064 0 0 0 7.421-9.208h-4.62c-1.11 1.97-3.57 3.4-6.695 3.709-.435.044-.871.066-1.308.065-2.754 0-5.125-.858-6.642-2.244A5.098 5.098 0 0 1 46.548 50c0-1.739.928-3.256 2.45-4.336ZM87.123 44.234v-6.29h-16.84V62.054h4.228v-8.7h11.321v-6.29H74.51v-2.83h12.612ZM26.852 48.392H25.07l-3.17-6.316-2.074-4.131h-6.953v24.108h4.228V47.657h2.307l4.684 9.82h3.982l4.681-9.82h2.307V62.053h4.228V37.945h-7.199l-5.24 10.447Z"
				fill="#000"
			/>
		</g>
		<defs>
			<clipPath id="gh436h">
				<path fill="#fff" d="M0 0h99.997v100H0z" />
			</clipPath>
		</defs>
	</svg>
</template>

<script>
export default {
	props: { blok: { type: Object, default: () => ({}) } }
}
</script>
