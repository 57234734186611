<template>
	<div class="bar-wrapper">
		<button
			v-if="blok.display_modal"
			@click="showModal"
			v-editable="blok"
			:key="blok.name"
			class="bar"
			:class="[blok.cover]"
		>
			<p class="text-center mb-6">
				{{ blok.name }}
			</p>
			<div
				class="image-wrapper border border-solid border-black"
				:class="[!blok.caption ? 'mb-10' : '']"
			>
				<blok-image
					class="bar-poster"
					:class="blok.name.toLowerCase().replace(/ /g, '-')"
					:blok="{ image: blok.image }"
				/>
			</div>
			<p v-if="blok.caption" class="text-center mt-6">
				{{ blok.caption }}
			</p>
		</button>
		<a
			v-else-if="blok.link"
			v-editable="blok"
			:key="blok.name"
			class="bar"
			:class="[blok.cover]"
			:href="blok.link"
		>
			<p class="text-center mb-6">
				{{ blok.name }}
			</p>
			<div
				class="image-wrapper border border-solid border-black"
				:class="[!blok.caption ? 'mb-10' : '']"
			>
				<blok-image
					class="bar-poster"
					:class="blok.name.toLowerCase().replace(/ /g, '-')"
					:blok="{ image: blok.image }"
				/>
			</div>
			<p v-if="blok.caption" class="text-center mt-6">
				{{ blok.caption }}
			</p>
		</a>
		<div
			v-else
			v-editable="blok"
			:key="blok.name"
			class="bar"
			:class="[blok.cover]"
		>
			<p class="text-center mb-6">
				{{ blok.name }}
			</p>
			<div
				class="image-wrapper border border-solid border-black"
				:class="[!blok.caption ? 'mb-10' : '']"
			>
				<blok-image
					class="bar-poster"
					:class="blok.name.toLowerCase().replace(/ /g, '-')"
					:blok="{ image: blok.image }"
				/>
			</div>
			<p v-if="blok.caption" class="text-center mt-6">
				{{ blok.caption }}
			</p>
		</div>
		<Modal
			v-if="blok.display_modal"
			v-show="isModalVisible"
			@close="closeModal"
		>
			<template v-slot:left>
				<blok-image
					class="modal-bar-poster border border-solid border-black"
					:class="blok.name.toLowerCase().replace(/ /g, '-')"
					:blok="{ image: blok.image }"
				/>
			</template>

			<template v-slot:right>
				<blok-image
					class="modal-bar-logo"
					:blok="{ image: blok.logo }"
					:style="{
						'max-width': blok.logo_max_width + 'px'
					}"
				/>
				<a
					:href="blok.link"
					target="_blank"
					v-track:click="{
						category: 'bar',
						label: blok.name
					}"
					class="mt-6 mb-2 modal-bar-link"
				>
					{{ blok.name }}
				</a>
				<p class="mb-6 modal-bar-caption">
					{{ blok.caption }}
				</p>
				<p v-if="blok.description" class="modal-bar-description">
					{{ blok.description }}
				</p>
				<div v-if="blok.featuring">
					<span class="btn modal-bar-featuring">Featuring</span>
					<div class="modal-bar-featuring-blks">
						<component
							:key="blok._uid"
							v-for="blok in blok.featuring"
							:blok="blok"
							:is="blok.component | dashify"
						></component>
					</div>
				</div>
			</template>
		</Modal>
	</div>
</template>

<script>
import Modal from './Modal.vue'

export default {
	props: { blok: { type: Object, default: () => ({}) } },
	components: {
		Modal
	},
	data() {
		return {
			isModalVisible: false
		}
	},
	methods: {
		showModal() {
			this.isModalVisible = true
		},
		closeModal() {
			this.isModalVisible = false
		}
	}
}
</script>

<style lang="scss">
.bar-wrapper {
	width: 33.33333%;
	padding: 2rem 3.75rem;
	margin: 0 0 -1px 0 !important;
	border-right: 1px solid #000;
	border-bottom: 1px solid #000;
	display: flex;
	align-items: center;

	&:nth-child(3n + 3) {
		border-right: 0;
	}

	@include media('<de') {
		width: 100%;
		border-right: 0;
		padding: 1.5625rem;
	}
}

.bar {
	width: 100%;
	outline: none !important;

	@include media('<de') {
		@apply mb-5 mx-1;
	}

	.image-wrapper {
		position: relative;
		padding-bottom: 100%;
		overflow: hidden;

		.bar-poster {
			max-width: none;
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
			height: 100%;
			width: 100%;
			transition: all 0.3s ease;
		}
	}

	&:hover {
		.image-wrapper {
			.bar-poster {
				transform: scale(1.1);
			}
		}
	}

	/*&.bar--dark {
		.image-wrapper::after {
			@apply bg-dark;
		}
	}

	&.bar--light {
		.image-wrapper::after {
			@apply bg-white;
		}
	}*/
}
</style>
