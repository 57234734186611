<template>
	<svg
		width="130"
		height="130"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 130 130"
	>
		<path
			d="M61.282 3.202a6.5 6.5 0 0 1 7.436 0l4.153 2.896a7.5 7.5 0 0 0 6.068 1.134l4.918-1.2A6.5 6.5 0 0 1 90.79 8.72l2.826 4.2a7.5 7.5 0 0 0 5.248 3.25l5.02.657a6.498 6.498 0 0 1 5.495 5.01l1.118 4.937a7.502 7.502 0 0 0 3.721 4.927l4.443 2.426a6.5 6.5 0 0 1 3.314 6.657l-.741 5.008a7.502 7.502 0 0 0 1.689 5.937l3.267 3.868a6.5 6.5 0 0 1 .686 7.404l-2.5 4.402a7.499 7.499 0 0 0-.57 6.147l1.649 4.787a6.5 6.5 0 0 1-2.035 7.152l-3.922 3.201a7.5 7.5 0 0 0-2.751 5.526l-.192 5.06a6.499 6.499 0 0 1-4.481 5.933l-4.813 1.569a7.497 7.497 0 0 0-4.562 4.159l-2.007 4.648a6.5 6.5 0 0 1-6.322 3.915l-5.055-.276a7.498 7.498 0 0 0-5.756 2.23l-3.55 3.609a6.501 6.501 0 0 1-7.31 1.367l-4.613-2.084a7.5 7.5 0 0 0-6.174 0l-4.614 2.084a6.501 6.501 0 0 1-7.309-1.367l-3.55-3.609a7.498 7.498 0 0 0-5.756-2.23l-5.055.276a6.5 6.5 0 0 1-6.322-3.915l-2.007-4.648a7.5 7.5 0 0 0-4.562-4.159l-4.813-1.569a6.498 6.498 0 0 1-4.481-5.934l-.192-5.059a7.5 7.5 0 0 0-2.751-5.526l-3.922-3.201a6.5 6.5 0 0 1-2.035-7.152l1.649-4.787a7.5 7.5 0 0 0-.57-6.147L3.123 63a6.5 6.5 0 0 1 .686-7.404l3.267-3.868a7.5 7.5 0 0 0 1.689-5.937l-.741-5.008a6.5 6.5 0 0 1 3.314-6.657l4.444-2.426a7.5 7.5 0 0 0 3.72-4.927l1.117-4.937a6.5 6.5 0 0 1 5.496-5.01l5.02-.657a7.5 7.5 0 0 0 5.248-3.25l2.826-4.2a6.5 6.5 0 0 1 6.934-2.687l4.918 1.2a7.5 7.5 0 0 0 6.068-1.134l4.153-2.896Z"
			fill="#457730"
			stroke="#000"
			class="icon-bg"
		/>
		<path
			d="M84.616 28.575 81.011 20l-20.395 8.575h24L90.78 43.24l-22.166 9.322.553 13.517-25.19 1.028-1.028-25.19 19.589-.802-4.785-11.346 2.849-1.194H32l7.837 74.685h46.96l8.796-74.685H84.616Zm-13.66 74.547L53.142 85.294 70.97 67.466l17.828 17.828-17.842 17.828Z"
			fill="#000"
		/>
		<path
			d="m68.139 40.889-5.601.226 5.002 11.898 1.074-.451-.475-11.673Z"
			fill="#000"
		/>
		<path
			d="M28.718 54.848V52.58h-8.352V65h1.386v-4.986h6.228v-2.268h-6.228v-2.898h6.966Zm3.3 7.884V59.78h6.821v-2.052h-6.822v-2.88h7.416V52.58h-8.802V65h8.892v-2.268h-7.506ZM51.534 65h1.494l-4.914-12.42h-2.628L40.573 65h1.638l1.062-2.574h7.2L51.535 65Zm-7.326-4.842 2.088-5.022h1.134l2.088 5.022h-5.31Zm17.89-7.578h-9.955v2.268h4.284V65h1.386V54.848h4.284V52.58Zm6.685 12.636c2.916 0 5.112-2.142 5.112-5.004V52.58H72.51v7.596c0 1.548-1.602 2.718-3.726 2.718-2.124 0-3.708-1.17-3.708-2.718V52.58H63.69v7.632c0 2.862 2.196 5.004 5.094 5.004ZM84.542 65h1.782l-3.078-4.374c1.728-.504 2.934-2.016 2.934-3.924 0-2.358-1.818-4.122-4.212-4.122h-5.616V65h1.386v-4.194h3.798L84.542 65Zm-6.804-10.152h4.176c1.746 0 2.808.72 2.808 1.854 0 1.116-1.062 1.836-2.808 1.836h-4.176v-3.69Zm11.83 7.884V59.78h6.822v-2.052h-6.822v-2.88h7.416V52.58h-8.802V65h8.892v-2.268h-7.506Zm14.389-10.152h-4.824V65h4.824c3.618 0 6.318-2.664 6.318-6.21s-2.7-6.21-6.318-6.21Zm-.036 10.152h-3.402v-7.884h3.402c2.79 0 4.896 1.692 4.896 3.942s-2.106 3.942-4.896 3.942Zm-65.55 23V82.78h6.823v-2.052h-6.822v-2.88h7.416V75.58h-8.802V88h8.892v-2.268h-7.506ZM51.662 88h2.557l4.752-12.42h-1.692l-3.888 9.738h-1.062l-3.906-9.738H46.91L51.662 88Zm10.318-2.268V82.78h6.822v-2.052H61.98v-2.88h7.416V75.58h-8.802V88h8.892v-2.268H61.98ZM71.544 88h1.386v-8.874h.63L80.04 88h2.034V75.58H80.67v8.874h-.63l-6.48-8.874h-2.016V88Zm22.327-12.42h-9.954v2.268H88.2V88h1.386V77.848h4.284V75.58Z"
			fill="#E5E2D0"
		/>
	</svg>
</template>

<script>
export default {
	props: { blok: { type: Object, default: () => ({}) } }
}
</script>
