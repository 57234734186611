<template>
	<div
		v-editable="blok"
		class="event"
		:class="[blok.fullWidth ? 'event--full' : 'event--grid']"
	>
		<div class="event__wrap">
			<h5 class="font-bold mb-6 event__title" v-html="blok.title"></h5>
			<div
				class="event__img-wrapper border border-solid border-black mb-4"
			>
				<blok-image class="w-full" :blok="{ image: blok.image }" />
				<div v-if="blok.badge == 'industry-event'" class="event__badge">
					<BadgeIndustryEvent />
				</div>
				<div
					v-else-if="blok.badge == 'featured-event'"
					class="event__badge"
				>
					<BadgeFeaturedEvent />
				</div>
			</div>
			<div class="event__btm">
				<div
					class="event__details"
					:class="[
						blok.fullWidth
							? ''
							: 'border-b border-solid border-black pb-3 mb-6'
					]"
				>
					<p class="event__dates" v-html="blok.date"></p>
					<p class="event__location mt-1">
						{{ blok.location }}
					</p>
					<p
						class="event__tickets mt-1"
						v-if="blok.status !== 'soldout'"
					>
						{{ blok.price > 0 ? '$' + blok.price : '' }}
					</p>
					<p
						class="mt-1"
						v-if="blok.maxGuests"
						v-html="blok.maxGuests"
					></p>
				</div>
				<div class="event__description">
					<span
						v-if="blok.description"
						v-html="
							blok.description.substring(
								0,
								readmore ? 9999999 : 75
							) + (readmore ? '' : '...')
						"
					></span>
					&nbsp;
					<a
						@click="readmore = !readmore"
						class="readmore underline"
						v-if="blok.description"
						v-track:click="{
							category: 'event-list',
							label: 'Read More - ' + blok.title
						}"
						>read {{ readmore ? 'less' : 'more' }}</a
					>
				</div>
				<div class="event__action">
					<p
						v-if="blok.status === 'booknow' && blok.link"
						:class="[blok.fullWidth ? '' : 'mt-5']"
					>
						<a
							class="btn bg-blue text-white uppercase self-start de:self-end"
							target="_blank"
							:href="blok.link"
							v-track:click="{
								category: 'event-list',
								label: blok.title + ' book now'
							}"
						>
							Book Now!</a
						>
					</p>
					<p
						v-if="blok.status === 'onsale' && blok.link"
						:class="[blok.fullWidth ? '' : 'mt-5']"
					>
						<a
							class="btn bg-blue text-white uppercase self-start de:self-end"
							target="_blank"
							:href="blok.link"
							v-track:click="{
								category: 'event-list',
								label: blok.title + ' buy tickets'
							}"
							>{{
								blok.link.indexOf('mailto') > -1
									? 'RSVP Here'
									: blok.price == 0
									? 'Get FREE Tickets'
									: 'Book Now!'
							}}
							></a
						>
					</p>
					<p
						v-if="blok.status === 'soldout'"
						class="btn inline-block bg-red text-white"
						:class="[blok.fullWidth ? '' : 'mt-5']"
					>
						SOLD OUT!
					</p>
					<p
						v-if="blok.status === 'onsale' && !blok.link"
						class="btn inline-block bg-green text-white"
						:class="[blok.fullWidth ? '' : 'mt-5']"
					>
						No Ticket Required
					</p>
					<!-- <a
					v-if="
						blok.status === 'onsale' &&
							blok.price <= 0 &&
							blok.link &&
							blok.link.indexOf('mailto') <= -1
					"
					:href="blok.link"
					target="_blank"
					class="mt-10 btn inline-block bg-orange"
					v-track:click="{
						category: 'event-list',
						label: blok.title + ' view event page'
					}"
				>
					View Event Page >
				</a> -->

					<div
						v-if="blok.status === 'pending'"
						:class="[blok.fullWidth ? '' : 'mt-5']"
					>
						<p v-if="blok.ticketWhen">{{ blok.ticketWhen }}</p>
						<a
							class="btn self-start de:self-end mt-3"
							@click="$store.dispatch('toggleNewsletter')"
							v-track:click="{
								category: 'event-list',
								label: blok.title + ' get notified'
							}"
							>Get notified ></a
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BadgeFeaturedEvent from '~/components/BadgeFeaturedEvent.vue'
import BadgeIndustryEvent from '~/components/BadgeIndustryEvent.vue'
export default {
	props: { blok: { type: Object, default: () => ({}) } },
	data: () => ({
		readmore: false
	}),
	components: {
		BadgeFeaturedEvent,
		BadgeIndustryEvent
	}
}
</script>

<style lang="scss">
.event {
	padding: 2rem 3.75rem;
	margin: 0 0 -1px 0 !important;

	&--grid {
		width: 33.33333%;
		border-right: 1px solid #000;
		border-bottom: 1px solid #000;

		&:nth-child(3n + 3) {
			border-right: 0;
		}

		.event__wrap {
			height: 100%;
			display: flex;
			flex-flow: column;
		}

		.event__img-wrapper {
			padding-bottom: 100%;
		}

		.event__btm {
			height: 100%;
			display: flex;
			flex-flow: column;
		}

		.event__title {
			min-height: 3.75rem;

			@include media('<de') {
				min-height: 0;
			}
		}

		.event__action {
			margin-top: auto;
		}
	}

	&--full {
		width: 100%;

		.event__img-wrapper {
			padding-bottom: 56.25%;
		}

		.event__btm {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			justify-content: space-between;
			margin-left: -3.75rem;
			width: calc(100% + 3.75rem + 3.75rem);

			@include media('<de') {
				width: 100%;
				grid-template-columns: 1fr;
				grid-gap: 1rem;
				margin-left: 0;
			}

			.event__details {
				padding: 0 3.75rem;

				@include media('<de') {
					padding: 0;
				}
			}

			.event__description {
				padding: 0 0 1rem;

				@include media('<de') {
					padding: 0;
				}
			}
			.event__action {
				padding: 0 3.75rem;
				text-align: right;

				@include media('<de') {
					padding: 0;
					text-align: left;
				}
			}
		}
	}

	@include media('<de') {
		width: 100%;
		border-right: 0;
		padding: 1.5625rem;
	}

	.event__img-wrapper {
		position: relative;

		img {
			max-width: none;
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
			height: 100%;
			width: 100%;
			transition: all 0.3s ease;
		}
	}

	.event__badge {
		position: absolute;
		z-index: 2;
		width: 9rem;
		height: 9rem;
		right: 0;
		top: 50%;
		transform: translateY(-50%) translateX(25%);

		@include media('<de') {
			width: 6rem;
			height: 6rem;
			transform: translateY(25%) translateX(15%);
			top: auto;
			bottom: 0;
		}

		svg {
			width: 100%;
			height: 100%;
		}

		.icon-bg {
			animation: rotate 20s linear infinite both;
			transform-origin: center;
		}
	}

	@keyframes rotate {
		0% {
			transform: rotate(0);
		}
		100% {
			transform: rotate(1turn);
		}
	}
}
</style>
