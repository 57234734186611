<template>
	<div
		v-editable="blok"
		class="agrid flex-col flex de:flex-row"
		:class="[
			blok.wrap ? 'flex-wrap' : '',
			blok.justify,
			blok.remove_padding ? 'agrid--no-padding' : ''
		]"
	>
		<component
			:key="blok._uid"
			v-for="blok in blok.columns"
			:blok="blok"
			:is="blok.component | dashify"
		></component>
	</div>
</template>

<script>
export default {
	props: { blok: { type: Object, default: () => ({}) } }
}
</script>

<style lang="scss">
.agrid {
	> * {
		@apply mx-0;

		@screen de {
			@apply mx-4;

			&:first-child {
				@apply ml-0;
			}

			&:last-child {
				@apply mr-0;
			}
		}
	}
}

.agrid--no-padding {
	margin-left: -60px;
	margin-right: -60px;

	@include media('<de') {
		margin-left: -25px;
		margin-right: -25px;
	}
}
</style>
