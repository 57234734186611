var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"bg-burnt"},[_c('div',{staticClass:"py-10 content-wrapper flex justify-between flex-wrap"},[_c('div',{staticClass:"social flex items-center w-full de:w-auto"},[_c('a',{directives:[{name:"track",rawName:"v-track:click",value:({
					category: 'footer',
					label: 'instagram'
				}),expression:"{\n\t\t\t\t\tcategory: 'footer',\n\t\t\t\t\tlabel: 'instagram'\n\t\t\t\t}",arg:"click"}],staticClass:"block mr-5 light",attrs:{"href":"https://www.instagram.com/melbournecocktailfestival/","target":"_blank"}},[_c('i',{staticClass:"ico ico-instagram text-bigger"})]),_vm._v(" "),_c('a',{directives:[{name:"track",rawName:"v-track:click",value:({
					category: 'footer',
					label: 'facebook'
				}),expression:"{\n\t\t\t\t\tcategory: 'footer',\n\t\t\t\t\tlabel: 'facebook'\n\t\t\t\t}",arg:"click"}],staticClass:"block light",attrs:{"href":"https://www.facebook.com/melbournecocktailfestival","target":"_blank"}},[_c('i',{staticClass:"ico ico-facebook text-bigger"})]),_vm._v(" "),_c('a',{directives:[{name:"track",rawName:"v-track:click",value:({
					category: 'footer',
					label: 'newsletter'
				}),expression:"{\n\t\t\t\t\tcategory: 'footer',\n\t\t\t\t\tlabel: 'newsletter'\n\t\t\t\t}",arg:"click"}],staticClass:"light de:ml-5 ml-auto btn text-dark de:text-cream",on:{"click":function($event){return _vm.$store.dispatch('toggleNewsletter')}}},[_c('span',[_vm._v("Stay in the know ")]),_c('span',[_vm._v(">")])])]),_vm._v(" "),_c('p',{staticClass:"acknowledgement max-w-sm text-small self-center font-normal my-10 py-10 de:py-0"},[_c('a',{directives:[{name:"track",rawName:"v-track:click",value:({
					category: 'footer',
					label: 'enquiries'
				}),expression:"{\n\t\t\t\t\tcategory: 'footer',\n\t\t\t\t\tlabel: 'enquiries'\n\t\t\t\t}",arg:"click"}],staticClass:"light",attrs:{"href":"mailto:drink@melbournecocktailfestival.io"}},[_vm._v("Contact: drink@melbournecocktailfestival.io\n\t\t\t")])]),_vm._v(" "),_c('div',{staticClass:"ws"},[_c('p',{staticClass:"text-smaller font-hairline"},[_vm._v("Brought to life by")]),_vm._v(" "),_c('a',{directives:[{name:"track",rawName:"v-track:click",value:({
					category: 'footer',
					label: 'worksmith'
				}),expression:"{\n\t\t\t\t\tcategory: 'footer',\n\t\t\t\t\tlabel: 'worksmith'\n\t\t\t\t}",arg:"click"}],staticClass:"mt-3 text-large light",attrs:{"target":"_blank","href":"https://worksmith.io"}},[_c('i',{staticClass:"ico ico-logo"}),_vm._v(" "),_c('span',{staticClass:"font-hairline"},[_vm._v(">")])])])]),_vm._v(" "),_c('div',{staticClass:"border-t border-white mt-5 py-5 text-smaller font-normal"},[_c('div',{staticClass:"content-wrapper flex flex-col de:flex-row items-center"},[_c('div',{staticClass:"de:ml-auto mt-2 de:mt-0 w-full de:w-auto"},[_c('a',{directives:[{name:"track",rawName:"v-track:click",value:({
						category: 'footer',
						label: 'COVIDSafe Plan'
					}),expression:"{\n\t\t\t\t\t\tcategory: 'footer',\n\t\t\t\t\t\tlabel: 'COVIDSafe Plan'\n\t\t\t\t\t}",arg:"click"}],staticClass:"light mr-5 my-3 de:mb-0 block de:inline-block",attrs:{"target":"_blank","href":"/COVIDSafe.pdf"}},[_vm._v("COVIDSafe Plan")]),_vm._v(" "),_c('a',{directives:[{name:"track",rawName:"v-track:click",value:({
						category: 'footer',
						label: 'privacy policy'
					}),expression:"{\n\t\t\t\t\t\tcategory: 'footer',\n\t\t\t\t\t\tlabel: 'privacy policy'\n\t\t\t\t\t}",arg:"click"}],staticClass:"light mr-5 my-3 de:mb-0 block de:inline-block",attrs:{"target":"_blank","href":"/Melbourne_Cocktail_Festival_Privacy_Policy.pdf"}},[_vm._v("Privacy Policy")]),_vm._v(" "),_c('span',[_vm._v("© Worksmith.")]),_vm._v(" "),_c('span',{staticClass:"ml-4"},[_vm._v("Grow. Hospitality. Together")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }