<template>
	<img
		:class="[]"
		:style="{
			//'max-height': 'none',
			//'max-width': 'none',
			width: `${
				$device.isMobile ? blok.mobileWidth : blok.width
			} !important`,
			height: `${
				$device.isMobile ? blok.mobileHeight : blok.height
			} !important`
		}"
		:src="blok.image.filename"
	/>
</template>

<script>
export default {
	props: { blok: { type: Object, default: () => ({}) } },
	mounted() {
		console.log('mounted image', this.blok.image)
	}
}
</script>
