<template>
	<a
		@click="goto"
		class="btn"
		:class="[blok.margin, blok.size, blok.color]"
		:href="blok.link"
		:target="blok.target"
		v-track:click="{
			category: 'button',
			label: blok.text
		}"
		>{{ blok.text }}</a
	>
</template>

<script>
export default {
	props: {
		blok: {
			type: Object,
			default: () => ({})
		}
	},

	methods: {
		goto($event) {
			if (this.blok.link === 'newsletter') {
				this.$store.dispatch('toggleNewsletter')
				$event.preventDefault()
				$event.stopPropagation()
				return false
			}
		}
	}
}
</script>
