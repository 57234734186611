export const state = () => ({
	showNewsletter: false,
	notifications: [],
	cacheVersion: '',
	layout: {}
})

export const mutations = {
	setNewsletter(state, status) {
		state.showNewsletter = status
	},
	setNotification(state, notification) {
		state.notifications.push(notification)
	},

	removeNotification(state, message) {
		const newN = state.notifications.filter(n => n.text !== message)
		state.notifications = newN
	},

	setCacheVersion(state, version) {
		state.cacheVersion = version
	},
	SET_LAYOUT(state, layout) {
		console.log('saving layout', layout)
		state.layout = layout
	}
}

export const actions = {
	toggleNewsletter({ commit, state }) {
		commit('setNewsletter', !state.showNewsletter)
	},

	addNotification({ commit }, notification) {
		commit('setNotification', notification)
	},

	removeNotification({ commit }, message) {
		commit('removeNotification', message)
	},

	loadCacheVersion({ commit }) {
		return this.$storyapi.get(`cdn/spaces/me`).then(res => {
			commit('setCacheVersion', res.data.space.version)
		})
	},
	async loadLayout(stuff) {
		const data = await this._vm.$storyapi.get(`cdn/stories/`, {
			starts_with: 'layout',
			version:
				this._vm.isDev || Boolean(true) === true
					? 'draft'
					: 'published',
			cv: this.state.cacheVersion,
			per_page: 100
		})

		stuff.commit('SET_LAYOUT', data.data.stories[0].content)
	}
}

export const getters = {
	showNewsletter(state) {
		return state.showNewsletter
	},
	notifications(state) {
		return state.notifications
	}
}
