import Vue from 'vue'
import HomeHero from '~/components/HomeHero.vue'
import HomeHeroBottom from '~/components/HomeHeroBottom.vue'
import Page from '~/components/Page.vue'
import Image from '~/components/Image.vue'
import ImageBlock from '~/components/ImageBlock.vue'
import Button from '~/components/Button.vue'

import Event from '~/components/Event.vue'
import Staff from '~/components/Staff.vue'
import Bar from '~/components/Bar.vue'

import Title from '~/components/Title.vue'
import SubTitle from '~/components/SubTitle.vue'
import Grid from '~/components/Grid.vue'
import Partners from '~/components/Partners.vue'
import Partner from '~/components/Partner.vue'
import Section from '~/components/Section.vue'

Vue.component('blok-page', Page)
Vue.component('blok-grid', Grid)
Vue.component('blok-section', Section)
Vue.component('blok-title', Title)
Vue.component('blok-subtitle', SubTitle)
Vue.component('blok-image', Image)
Vue.component('blok-image--block', ImageBlock)
Vue.component('blok-button', Button)

Vue.component('blok-event', Event)
Vue.component('blok-staff', Staff)
Vue.component('blok-bar', Bar)
Vue.component('blok-partners', Partners)
Vue.component('blok-partner', Partner)

Vue.component('blok-home-hero', HomeHero)
Vue.component('blok-home-hero-bottom', HomeHeroBottom)
