<template>
	<div class="staff">
		<div class="staff__image-wrapper">
			<blok-image :blok="{ image: blok.photo }" />
		</div>
		<h5 class="staff__name">{{ blok.name }}</h5>

		<p class="staff__description" v-html="blok.description"></p>
	</div>
</template>

<script>
export default {
	props: { blok: { type: Object, default: () => ({}) } }
}
</script>

<style lang="scss">
.staff {
	@apply mb-12;
	text-align: center;
	width: 25%;

	@include media('<de') {
		width: 100%;
	}

	&:not(:first-child) {
		.staff__image-wrapper img {
			top: 150px;
		}
	}

	&:nth-child(4) {
		.staff__image-wrapper img {
			top: 125px;
		}
	}

	&__image {
		&-wrapper {
			@apply mb-6;

			margin-left: auto;
			margin-right: auto;
			position: relative;
			width: 160px;
			height: 160px;
			border-radius: 100%;
			overflow: hidden;

			@include media('<de') {
				@apply mb-4;
			}

			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				// min-width: 100%;
				width: 250px;
				max-width: none;

				&.orlando,
				&.roscoe {
					top: 125px;
				}
				// height: auto;
			}
		}
	}

	&__description {
		@apply mt-3;
	}
}
</style>
