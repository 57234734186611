<template>
	<div
		v-editable="blok"
		class="subtitle"
		:class="[blok.enable_padding ? 'subtitle--padded' : '']"
	>
		<h3 v-html="blok.subtitle">{{ blok.subtitle }}</h3>
	</div>
</template>

<script>
export default {
	props: { blok: { type: Object, default: () => ({}) } }
}
</script>

<style lang="scss">
.subtitle {
	align-self: center;
}

h3 {
	@apply m-0;
}

.subtitle--padded {
	padding: 60px;

	@include media('<de') {
		padding: 25px;
	}
}
</style>
