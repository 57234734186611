<template>
	<header class="top-0 w-full bg-cream z-50">
		<div
			class="content-wrapper flex items-center justify-between de:justify-start py-2 de:py-4"
		>
			<div class="logo">
				<nuxt-link
					to="/"
					v-track:click="{
						category: 'header',
						label: 'logo'
					}"
				>
					<Logo />
				</nuxt-link>
			</div>
			<div>
				<component
					v-if="$device.isMobile"
					class="mx-4 de:mx-10 de:block"
					:key="blok._uid"
					v-for="blok in layout.CTA"
					:blok="blok"
					:is="blok.component | dashify"
				></component>
			</div>
			<!-- <div class="dates px-2 mx-2 ml-5 de:px-5 de:mx-5">
				<p class="font-hairline">
					Feb 26 <br />
					<span class="text-small">&mdash;</span> Mar 1
				</p>
				<p class="font-extrabold text-biggest">2020</p>
			</div> -->
			<div class="menu relative de:ml-auto">
				<div class="flex items-center">
					<!-- <a
						target="_blank"
						class="btn mr-10 hidden de:block"
						href="https://www.goodspiritstore.com/"
						v-track:click="{
							category: 'header',
							label: 'buy good spirits'
						}"
						><span>Shop GOODSPIRITS ></span></a
					> -->
					<!--<a
						@click="$store.dispatch('toggleNewsletter')"
						class="btn mx-4 de:mx-10 de:block"
						v-track:click="{
							category: 'footer',
							label: 'newsletter'
						}"
						><span>Stay in the know </span><span>></span></a
					>-->
					<component
						v-if="!$device.isMobile"
						class="mx-4 de:mx-10 de:block"
						:key="blok._uid"
						v-for="blok in layout.CTA"
						:blok="blok"
						:is="blok.component | dashify"
					></component>

					<a
						class="ico ico-mcf-hamburger cursor-pointer py-2 text-larger"
						@click="showNav = !showNav"
						v-if="!showNav"
					></a>
					<a
						class="ico ico-mcf-close cursor-pointer text-larger"
						@click="showNav = !showNav"
						v-if="showNav"
					></a>
				</div>
				<nav
					class="bg-dark bg-burnt w-screen de:w-auto de:absolute"
					v-if="showNav"
					@click="showNav = !showNav"
				>
					<ul>
						<li
							v-for="item in layout.navigation"
							v-if="
								($device.isMobile &&
									item.views.includes('mobile')) ||
									(!$device.isMobile &&
										item.views.includes('desktop'))
							"
							v-track:click="{
								category: 'nav',
								label: item.text
							}"
							:class="[item.style]"
						>
							<nuxt-link
								v-if="item.link.linktype === 'story'"
								:to="
									'/' +
										item.link.cached_url +
										(item.link.cached_url.indexOf('/') > -1
											? ''
											: '/')
								"
								:target="item.newTab ? '_blank' : '_self'"
								@click.native="$parent.showNav = false"
								>{{ item.text }}</nuxt-link
							>
							<a
								v-if="item.link.linktype === 'url'"
								:href="item.link.cached_url"
								:target="item.newTab ? '_blank' : '_self'"
								@click="$parent.showNav = false"
								>{{ item.text }}</a
							>
						</li>
						<!-- <li>
							<nuxt-link
								to="/"
								v-track:click="{
									category: 'header',
									label: 'home'
								}"
								><span>Home</span><span>></span></nuxt-link
							>
						</li>
						<li>
							<nuxt-link
								to="/about"
								v-track:click="{
									category: 'header',
									label: 'about'
								}"
								><span>About</span><span>></span></nuxt-link
							>
						</li> -->
						<!-- <li>
							<nuxt-link
								to="/symposium"
								v-track:click="{
									category: 'header',
									label: 'symposium'
								}"
								><span>Symposium</span><span>></span></nuxt-link
							>
						</li>
						<li>
							<nuxt-link
								to="/splash"
								v-track:click="{
									category: 'header',
									label: 'splash!'
								}"
								><span>Splash! Tasting Day</span
								><span>></span></nuxt-link
							>
						</li> -->
						<!-- <li>
							<nuxt-link
								to="/bar-safari"
								v-track:click="{
									category: 'header',
									label: 'bar safari'
								}"
								><span>Bar Safari</span
								><span>></span></nuxt-link
							>
						</li>
						<li>
							<nuxt-link
								to="/events"
								v-track:click="{
									category: 'header',
									label: 'events'
								}"
							>
								<span>Events</span><span>></span>
							</nuxt-link>
						</li> -->
						<!-- <li class="de:hidden">
							<a
								href="https://goodspiritstore.com"
								target="_blank"
								class=""
								v-track:click="{
									category: 'header',
									label: 'nav - get good spirits'
								}"
								><span>Get GOOD SPIRITS ></span
								><span>></span></a
							>
						</li> -->

						<!-- <li>
							<a
								target="_blank"
								href="https://worksmith.io"
								v-track:click="{
									category: 'header',
									label: 'worksmith'
								}"
								><span>Worksmith</span><span>></span></a
							>
						</li> -->
						<!-- <li class="bg-orange de:hidden">
							<a
								href="mailto:drink@melbournecocktailfestival.io"
								class="text-black"
								v-track:click="{
									category: 'header',
									label: 'newsletter - mobile'
								}"
								><span>Be a Part of MCF 2021</span
								><span>></span></a
							>
						</li> -->
						<!-- <li class="bg-orange de:hidden">
							<a
								@click="$store.dispatch('toggleNewsletter')"
								class="text-black"
								v-track:click="{
									category: 'header',
									label: 'newsletter - mobile'
								}"
								><span>Stay in the know</span><span>></span></a
							>
						</li> -->
					</ul>
				</nav>
			</div>
		</div>
	</header>
</template>

<script>
import { mapState } from 'vuex'
import Logo from '~/components/Logo.vue'
export default {
	computed: { ...mapState(['layout']) },
	data: () => {
		return {
			showNav: false
		}
	},
	components: {
		Logo
	}
}
</script>

<style lang="scss" scoped>
@import 'Header';
</style>
