<template>
	<section
		v-editable="blok"
		class="section border-t border-solid border-black"
		:class="[blok.padding]"
	>
		<a v-if="blok.id" :id="blok.id"></a>
		<div class="content-wrapper">
			<component
				:key="blok._uid"
				v-for="blok in blok.blocks"
				:blok="blok"
				:is="blok.component | dashify"
			></component>
		</div>
	</section>
</template>

<script>
export default {
	props: { blok: { type: Object, default: () => ({}) } }
}
</script>
