<template>
	<footer class="bg-burnt">
		<div class="py-10 content-wrapper flex justify-between flex-wrap">
			<div class="social flex items-center w-full de:w-auto">
				<a
					class="block mr-5 light"
					href="https://www.instagram.com/melbournecocktailfestival/"
					target="_blank"
					v-track:click="{
						category: 'footer',
						label: 'instagram'
					}"
					><i class="ico ico-instagram text-bigger"></i
				></a>
				<a
					class="block light"
					href="https://www.facebook.com/melbournecocktailfestival"
					target="_blank"
					v-track:click="{
						category: 'footer',
						label: 'facebook'
					}"
					><i class="ico ico-facebook text-bigger"></i
				></a>
				<a
					@click="$store.dispatch('toggleNewsletter')"
					class="light de:ml-5 ml-auto btn text-dark de:text-cream"
					v-track:click="{
						category: 'footer',
						label: 'newsletter'
					}"
					><span>Stay in the know </span><span>></span></a
				>
			</div>
			<!-- <nav>
				<ul>
					<li><a>Link 1</a></li>
					<li><a>Link 1</a></li>
					<li><a>Link 1</a></li>
					<li><a>Link 1</a></li>
				</ul>
			</nav>
			<nav>
				<ul>
					<li><a>Link 1</a></li>
					<li><a>Link 1</a></li>
					<li><a>Link 1</a></li>
					<li><a>Link 1</a></li>
				</ul>
			</nav>
			<nav>
				<ul>
					<li><a>Link 1</a></li>
					<li><a>Link 1</a></li>
					<li><a>Link 1</a></li>
					<li><a>Link 1</a></li>
				</ul>
			</nav> -->
			<p
				class="acknowledgement max-w-sm text-small self-center font-normal my-10 py-10 de:py-0"
			>
				<a
					class="light"
					href="mailto:drink@melbournecocktailfestival.io"
					v-track:click="{
						category: 'footer',
						label: 'enquiries'
					}"
					>Contact: drink@melbournecocktailfestival.io
				</a>
			</p>
			<div class="ws">
				<p class="text-smaller font-hairline">Brought to life by</p>
				<a
					target="_blank"
					href="https://worksmith.io"
					class="mt-3 text-large light"
					v-track:click="{
						category: 'footer',
						label: 'worksmith'
					}"
					><i class="ico ico-logo"></i>
					<span class="font-hairline">></span>
				</a>
			</div>
		</div>
		<div class="border-t border-white mt-5 py-5 text-smaller font-normal">
			<div class="content-wrapper flex flex-col de:flex-row items-center">
				<div class="de:ml-auto mt-2 de:mt-0 w-full de:w-auto">
					<a
						target="_blank"
						class="light mr-5 my-3 de:mb-0 block de:inline-block"
						href="/COVIDSafe.pdf"
						v-track:click="{
							category: 'footer',
							label: 'COVIDSafe Plan'
						}"
						>COVIDSafe Plan</a
					>
					<a
						target="_blank"
						class="light mr-5 my-3 de:mb-0 block de:inline-block"
						href="/Melbourne_Cocktail_Festival_Privacy_Policy.pdf"
						v-track:click="{
							category: 'footer',
							label: 'privacy policy'
						}"
						>Privacy Policy</a
					>
					<span>&copy; Worksmith.</span>
					<span class="ml-4">Grow. Hospitality. Together</span>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import 'Footer';
</style>
