import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ec0538e8 = () => interopDefault(import('../pages/old.about.vue' /* webpackChunkName: "pages/old.about" */))
const _4642f48b = () => interopDefault(import('../pages/old.bar-safari.vue' /* webpackChunkName: "pages/old.bar-safari" */))
const _c27c7fac = () => interopDefault(import('../pages/old.events.vue' /* webpackChunkName: "pages/old.events" */))
const _ac95835e = () => interopDefault(import('../pages/old.index.vue' /* webpackChunkName: "pages/old.index" */))
const _b35a9b10 = () => interopDefault(import('../pages/old.splash.vue' /* webpackChunkName: "pages/old.splash" */))
const _9f24671a = () => interopDefault(import('../pages/old.symposium.vue' /* webpackChunkName: "pages/old.symposium" */))
const _b186cf36 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/old.about",
    component: _ec0538e8,
    name: "old.about"
  }, {
    path: "/old.bar-safari",
    component: _4642f48b,
    name: "old.bar-safari"
  }, {
    path: "/old.events",
    component: _c27c7fac,
    name: "old.events"
  }, {
    path: "/old.index",
    component: _ac95835e,
    name: "old.index"
  }, {
    path: "/old.splash",
    component: _b35a9b10,
    name: "old.splash"
  }, {
    path: "/old.symposium",
    component: _9f24671a,
    name: "old.symposium"
  }, {
    path: "/*",
    component: _b186cf36,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
