<template>
	<svg
		width="130"
		height="130"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 130 130"
	>
		<path
			d="M61.282 3.202a6.5 6.5 0 0 1 7.436 0l4.153 2.896a7.5 7.5 0 0 0 6.068 1.134l4.918-1.2A6.5 6.5 0 0 1 90.79 8.72l2.826 4.2a7.5 7.5 0 0 0 5.248 3.25l5.02.657a6.498 6.498 0 0 1 5.495 5.01l1.118 4.937a7.502 7.502 0 0 0 3.721 4.927l4.443 2.426a6.5 6.5 0 0 1 3.314 6.657l-.741 5.008a7.502 7.502 0 0 0 1.689 5.937l3.267 3.868a6.5 6.5 0 0 1 .686 7.404l-2.5 4.402a7.499 7.499 0 0 0-.57 6.147l1.649 4.787a6.5 6.5 0 0 1-2.035 7.152l-3.922 3.201a7.5 7.5 0 0 0-2.751 5.526l-.192 5.06a6.499 6.499 0 0 1-4.481 5.933l-4.813 1.569a7.497 7.497 0 0 0-4.562 4.159l-2.007 4.648a6.5 6.5 0 0 1-6.322 3.915l-5.055-.276a7.498 7.498 0 0 0-5.756 2.23l-3.55 3.609a6.501 6.501 0 0 1-7.31 1.367l-4.613-2.084a7.5 7.5 0 0 0-6.174 0l-4.614 2.084a6.501 6.501 0 0 1-7.309-1.367l-3.55-3.609a7.498 7.498 0 0 0-5.756-2.23l-5.055.276a6.5 6.5 0 0 1-6.322-3.915l-2.007-4.648a7.5 7.5 0 0 0-4.562-4.159l-4.813-1.569a6.498 6.498 0 0 1-4.481-5.934l-.192-5.059a7.5 7.5 0 0 0-2.751-5.526l-3.922-3.201a6.5 6.5 0 0 1-2.035-7.152l1.649-4.787a7.5 7.5 0 0 0-.57-6.147L3.123 63a6.5 6.5 0 0 1 .686-7.404l3.267-3.868a7.5 7.5 0 0 0 1.689-5.937l-.741-5.008a6.5 6.5 0 0 1 3.314-6.657l4.444-2.426a7.5 7.5 0 0 0 3.72-4.927l1.117-4.937a6.5 6.5 0 0 1 5.496-5.01l5.02-.657a7.5 7.5 0 0 0 5.248-3.25l2.826-4.2a6.5 6.5 0 0 1 6.934-2.687l4.918 1.2a7.5 7.5 0 0 0 6.068-1.134l4.153-2.896Z"
			fill="#C53A27"
			stroke="#000"
			class="icon-bg"
		/>
		<path
			d="M81.526 29.427a.488.488 0 0 0 .335-.13.436.436 0 0 0 .139-.317v-2.532a.436.436 0 0 0-.139-.317.488.488 0 0 0-.335-.131H52.474a.488.488 0 0 0-.335.131.436.436 0 0 0-.139.317v2.532c0 .118.05.232.139.316.088.084.209.131.335.131h2.083l5.853 22.59a1.808 1.808 0 0 0-.943.6c-.238.29-.37.646-.376 1.014a1.64 1.64 0 0 0 .34 1.025 1.8 1.8 0 0 0 .923.628l-5.783 45.288h-2.097a.488.488 0 0 0-.335.132.436.436 0 0 0-.139.317v2.531c0 .119.05.233.139.317.088.084.209.131.335.131h29.038a.488.488 0 0 0 .335-.131.436.436 0 0 0 .139-.317v-2.531a.436.436 0 0 0-.139-.317.488.488 0 0 0-.335-.132h-2.046l-5.815-45.288a1.8 1.8 0 0 0 .922-.628 1.64 1.64 0 0 0 .341-1.025 1.645 1.645 0 0 0-.376-1.014c-.238-.291-.57-.501-.944-.6l5.873-22.59h2.06Z"
			fill="#000"
		/>
		<path
			d="M23.545 64h1.386V51.58h-1.386V64Zm4.078 0h1.386v-8.874h.63L36.12 64h2.034V51.58H36.75v8.874h-.63l-6.48-8.874h-2.016V64Zm18.06-12.42H40.86V64h4.824c3.618 0 6.318-2.664 6.318-6.21s-2.7-6.21-6.318-6.21Zm-.035 10.152h-3.402v-7.884h3.402c2.79 0 4.896 1.692 4.896 3.942s-2.106 3.942-4.896 3.942Zm13.255 2.484c2.916 0 5.112-2.142 5.112-5.004V51.58h-1.386v7.596c0 1.548-1.602 2.718-3.726 2.718-2.124 0-3.708-1.17-3.708-2.718V51.58h-1.386v7.632c0 2.862 2.196 5.004 5.094 5.004Zm11.798 0c2.592 0 4.914-1.386 4.914-3.87 0-2.232-2.016-3.366-4.824-3.744-1.314-.198-3.348-.594-3.348-1.656 0-.936 1.458-1.422 3.132-1.422 1.944 0 3.348.684 3.348 1.548h1.458c0-2.088-1.998-3.708-4.752-3.708-2.556 0-4.644 1.422-4.644 3.69 0 2.232 1.944 3.366 4.554 3.744 1.17.162 3.618.576 3.618 1.746 0 .936-1.548 1.512-3.348 1.512-2.07 0-3.618-.756-3.618-1.71h-1.458c0 2.178 2.124 3.87 4.968 3.87Zm15.67-12.636h-9.953v2.268h4.284V64h1.386V53.848h4.284V51.58ZM96.406 64h1.782l-3.078-4.374c1.728-.504 2.934-2.016 2.934-3.924 0-2.358-1.818-4.122-4.212-4.122h-5.616V64h1.386v-4.194H93.4L96.405 64Zm-6.804-10.152h4.176c1.746 0 2.808.72 2.808 1.854 0 1.116-1.062 1.836-2.808 1.836h-4.176v-3.69ZM103.315 64h1.404v-3.96l5.022-8.46h-1.746l-3.51 5.4h-1.044l-3.51-5.4h-1.638l5.022 8.46V64ZM39.372 84.732V81.78h6.822v-2.052h-6.822v-2.88h7.416V74.58h-8.802V87h8.892v-2.268h-7.506ZM52.662 87h2.556l4.752-12.42h-1.692l-3.888 9.738h-1.062l-3.906-9.738H47.91L52.662 87Zm10.317-2.268V81.78h6.822v-2.052H62.98v-2.88h7.416V74.58h-8.802V87h8.892v-2.268H62.98ZM72.544 87h1.386v-8.874h.63L81.04 87h2.034V74.58H81.67v8.874h-.63l-6.48-8.874h-2.016V87Zm22.327-12.42h-9.954v2.268H89.2V87h1.386V76.848h4.284V74.58Z"
			fill="#E5E2D0"
		/>
	</svg>
</template>

<script>
export default {
	props: { blok: { type: Object, default: () => ({}) } }
}
</script>
