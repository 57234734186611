<template>
	<div
		class="image-block border-l broder-r border-solid border-black"
		v-bind:style="{
			width: blok.width + '%',
			backgroundColor: blok.background_colour
		}"
	>
		<img :class="[]" :src="blok.image.filename" />
	</div>
</template>

<script>
export default {
	props: { blok: { type: Object, default: () => ({}) } }
}
</script>

<style lang="scss">
.image-block {
	flex-shrink: 0;
	padding: 2rem 3rem;
	display: flex;
	align-items: center;
	justify-content: center;

	@include media('<de') {
		width: 100% !important;
		padding: 2rem;
	}
}
</style>
