<template>
	<div v-editable="blok">
		<component
			:key="blok._uid"
			v-for="blk in blok.body"
			:blok="blk"
			:is="blk.component | dashify"
		></component>
	</div>
</template>

<script>
export default {
	props: { blok: { type: Object, default: () => ({}) } }
}
</script>
