<template>
	<div v-editable="blok">
		<blok-image :blok="{ image: blok.image }" />
	</div>
</template>

<script>
export default {
	props: { blok: { type: Object, default: () => ({}) } }
}
</script>
