var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"top-0 w-full bg-cream z-50"},[_c('div',{staticClass:"content-wrapper flex items-center justify-between de:justify-start py-2 de:py-4"},[_c('div',{staticClass:"logo"},[_c('nuxt-link',{directives:[{name:"track",rawName:"v-track:click",value:({
					category: 'header',
					label: 'logo'
				}),expression:"{\n\t\t\t\t\tcategory: 'header',\n\t\t\t\t\tlabel: 'logo'\n\t\t\t\t}",arg:"click"}],attrs:{"to":"/"}},[_c('Logo')],1)],1),_vm._v(" "),_c('div',_vm._l((_vm.layout.CTA),function(blok){return (_vm.$device.isMobile)?_c(_vm._f("dashify")(blok.component),{key:blok._uid,tag:"component",staticClass:"mx-4 de:mx-10 de:block",attrs:{"blok":blok}}):_vm._e()}),1),_vm._v(" "),_c('div',{staticClass:"menu relative de:ml-auto"},[_c('div',{staticClass:"flex items-center"},[_vm._l((_vm.layout.CTA),function(blok){return (!_vm.$device.isMobile)?_c(_vm._f("dashify")(blok.component),{key:blok._uid,tag:"component",staticClass:"mx-4 de:mx-10 de:block",attrs:{"blok":blok}}):_vm._e()}),_vm._v(" "),(!_vm.showNav)?_c('a',{staticClass:"ico ico-mcf-hamburger cursor-pointer py-2 text-larger",on:{"click":function($event){_vm.showNav = !_vm.showNav}}}):_vm._e(),_vm._v(" "),(_vm.showNav)?_c('a',{staticClass:"ico ico-mcf-close cursor-pointer text-larger",on:{"click":function($event){_vm.showNav = !_vm.showNav}}}):_vm._e()],2),_vm._v(" "),(_vm.showNav)?_c('nav',{staticClass:"bg-dark bg-burnt w-screen de:w-auto de:absolute",on:{"click":function($event){_vm.showNav = !_vm.showNav}}},[_c('ul',_vm._l((_vm.layout.navigation),function(item){return (
							(_vm.$device.isMobile &&
								item.views.includes('mobile')) ||
								(!_vm.$device.isMobile &&
									item.views.includes('desktop'))
						)?_c('li',{directives:[{name:"track",rawName:"v-track:click",value:({
							category: 'nav',
							label: item.text
						}),expression:"{\n\t\t\t\t\t\t\tcategory: 'nav',\n\t\t\t\t\t\t\tlabel: item.text\n\t\t\t\t\t\t}",arg:"click"}],class:[item.style]},[(item.link.linktype === 'story')?_c('nuxt-link',{attrs:{"to":'/' +
									item.link.cached_url +
									(item.link.cached_url.indexOf('/') > -1
										? ''
										: '/'),"target":item.newTab ? '_blank' : '_self'},nativeOn:{"click":function($event){_vm.$parent.showNav = false}}},[_vm._v(_vm._s(item.text))]):_vm._e(),_vm._v(" "),(item.link.linktype === 'url')?_c('a',{attrs:{"href":item.link.cached_url,"target":item.newTab ? '_blank' : '_self'},on:{"click":function($event){_vm.$parent.showNav = false}}},[_vm._v(_vm._s(item.text))]):_vm._e()],1):_vm._e()}),0)]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }