<template>
	<div v-editable="blok" class="partners">
		<div
			class="content-wrapper flex items-center justify-around de:justify-start flex-wrap mb-10"
		>
			<h5 class="border-b pb-3 font-normal text-small inline-block">
				<span>{{ blok.title }} </span><span></span>
			</h5>
		</div>
		<div
			class="content-wrapper flex flex-col de:flex-row items-center justify-around de:justify-start flex-wrap mb-10"
		>
			<component
				class="de:mr-10 mb-16 de:mb-10"
				:key="blok._uid"
				v-for="blok in blok.sponsors"
				:blok="blok"
				:is="blok.component | dashify"
			></component>
		</div>
	</div>
</template>

<script>
export default {
	props: { blok: { type: Object, default: () => ({}) } }
}
</script>

<style lang="scss" scoped>
@import 'Partners';
</style>
