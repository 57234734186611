<template>
	<transition name="modal-fade">
		<div class="modal-backdrop">
			<div
				role="dialog"
				aria-labelledby="modalTitle"
				aria-describedby="modalDescription"
				class="modal border border-solid border-black"
			>
				<button
					type="button"
					class="modal-btn-close border border-solid border-black"
					@click="close"
					aria-label="Close modal"
				>
					<span class="ico ico-mcf-close cursor-pointer"></span>
				</button>
				<div class="modal-wrapper">
					<div class="modal-left">
						<slot name="left">
							This is the default title!
						</slot>
					</div>
					<div class="modal-right">
						<slot name="right">
							This is the default body!
						</slot>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'Modal',
	methods: {
		close() {
			this.$emit('close')
		}
	}
}
</script>

<style lang="scss">
.modal-fade-enter,
.modal-fade-leave-to {
	opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
	transition: opacity 0.5s ease;
}

.modal-backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2222;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 1rem;
}

.modal {
	@apply bg-cream;
	overflow-x: hidden;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	text-align: center;
	position: relative;
	margin: auto;
	max-width: 800px;
	width: 100%;
}

.modal-btn-close {
	position: absolute;
	top: -1px;
	right: -1px;
	border: none;
	font-size: 1rem;
	padding: 0.5rem;
	cursor: pointer;
	color: #000;
	background: transparent;
	outline: none;
	@apply bg-orange;
	transition: all 0.5s ease;

	&:focus {
		outline: none;
	}

	&:hover {
		@apply bg-burnt;
		color: #fff;
	}
}

.modal-wrapper {
	padding: 2rem;
	display: flex;
	flex-flow: row wrap;

	@include media('<de') {
		padding: 1rem;
	}
}

.modal-left {
	width: 50%;

	@include media('<de') {
		width: 100%;
	}
}

.modal-bar-poster {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.modal-bar-logo {
	max-width: 90px;
	height: auto;
	width: 100%;
	margin: 0 auto;
}

.modal-bar-link,
.modal-bar-caption {
	font-size: 1rem;
}

.modal-bar-description {
	font-size: 0.875rem;
}

.modal-right {
	padding: 0 2rem;
	width: 50%;

	@include media('<de') {
		padding: 1rem;
		width: 100%;
	}
}

.modal-bar-featuring {
	display: inline-block;
	margin: 1.25rem 0;
}

.modal-bar-featuring-blks {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: center;
	gap: 1.25rem;

	@include media('<de') {
		gap: 1rem;
	}
}
</style>
